.header-container {
  width: 100%;
  background-color: aliceblue;
  height: 20vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-container h2 {
  font-size: 2rem;
  color: #3c3f50;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin: 0;
  padding: 0;
}
