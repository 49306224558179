.product-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 20px;
  width: 100%;
  max-width: 1200px;
  background-color: rgb(214, 223, 231);
}
