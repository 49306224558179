/* ./components/SawdustBriquettesInfo.css */
.briquettes-info {
  text-align: left;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.briquettes-info h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.briquettes-info p {
  font-size: 16px;
  color: #34495e;
  line-height: 1.6;
}

.briquettes-info h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #16a085;
}

.briquettes-info ul {
  padding-left: 20px;
}

.briquettes-info ul li {
  margin-bottom: 10px;
  color: #34495e;
}

.briquettes-info ul li strong {
  color: #2c3e50;
}
